<template>
  <v-container fluid>
    <v-row v-if="showHeaderPage">
      <v-col cols="12" md="3" align="start">
        <p class="title">Dados Principais</p>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12" md="3">
        <label class="label">CPF do Titular</label>
        <v-text-field
          v-model="holder.document"
          class="mt-2"
          placeholder="Informe o número"
          v-mask="'###.###.###-##'"
          outlined
          color="textPrimary"
          :disabled="isDetail"
          :rules="holder.document ? [rule.cpf] : []"
          validate-on-blur
          @input="observerHolderInformationData()"
        />
      </v-col>
      <v-col cols="12" md="3">
        <label class="label">Nome do Titular</label>
        <v-text-field
          v-model.trim="holder.name"
          class="mt-2"
          placeholder="Informe o nome"
          maxlength="70"
          outlined
          color="textPrimary"
          :disabled="isDetail"
          :rules="holder.name ? [rule.name, rule.validateIsAlphanumeric] : []"
          validate-on-blur
          @input="observerHolderInformationData()"
          @blur="holder.name = formatter.formatToTitleCase(holder.name)"
        />
      </v-col>
      <template v-if="showRegistrationPlate">
        <v-col cols="12" md="3">
          <label class="label">Matrícula do Titular</label>
          <v-text-field
            maxlength="50"
            v-model="holder.registrationPlate"
            class="mt-2"
            placeholder="Informe a matrícula"
            outlined
            color="textPrimary"
            :disabled="isDetail"
            @input="observerHolderInformationData()"
          />
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">Complemento da Matrícula do Titular</label>
          <v-text-field
            maxlength="50"
            v-model="holder.registrationComplement"
            class="mt-2"
            placeholder="Informe o complemento"
            outlined
            color="textPrimary"
            :disabled="isDetail"
            @input="observerHolderInformationData()"
          />
        </v-col>
      </template>
      <v-col v-show="showCardNumber" cols="12" md="3">
        <label class="label">Carteirinha do Titular</label>
        <v-text-field
          maxlength="50"
          v-model="holder.cardNumber"
          class="mt-2"
          placeholder="Informe a carteirinha"
          outlined
          color="textPrimary"
          :disabled="isDetail"
          @input="observerHolderInformationData()"
        />
      </v-col>
      <v-col v-if="showAdmissionDate" cols="12" md="3">
        <label class="label">Data de Admissão</label>
        <div class="d-flex flex-column">
          <v-text-field
            class="mt-2"
            outlined
            v-model="holder.admissionDate"
            :disabled="isDetail"
            append-icon="fas fa-calendar-alt"
            placeholder="DD/MM/YYYY"
            v-mask="'##/##/####'"
            @input="saveAdmissionDate(holder.admissionDate); observerHolderInformationData()"
            color="textPrimary"
            :rules="holder.admissionDate ? [rule.isAfterSpecificYear, rule.isDateValid, rule.requiredDate] : []"
            validate-on-blur
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';

export default ({
  data: () => ({
    holder: {
      document: null,
      name: null,
      registrationPlate: null,
      registrationComplement: null,
      cardNumber: null,
      admissionDate: null,
    },
  }),

  props: {
    holderInformationData: {
      type: Object,
      required: false,
    },
    isDetail: {
      type: Boolean,
      required: true,
    },
    showHeaderPage: {
      type: Boolean,
      required: true,
    },
    showAdmissionDate: {
      type: Boolean,
      required: true,
    },
    showRegistrationPlate: {
      type: Boolean,
      required: true,
    },
    showCardNumber: {
      type: Boolean,
      required: true,
    },
  },

  watch: {
    holderInformationData(val) {
      if (val) {
        this.loadBeneficiaryData();
      }
    },
  },

  async mounted() {
    await this.verifyFreeMovement();
    this.loadBeneficiaryData();
  },

  methods: {
    async verifyFreeMovement() {
      if (this.$route.query.freeMovement && (this.$route.query.freeMovement === 'true' || this.$route.query.freeMovement === true)) {
        this.isFreeMovement = true;
      }
    },
    async loadBeneficiaryData() {
      if (sessionStorage.getItem('movementRecord')) {
        const movementRecord = JSON.parse(sessionStorage.getItem('movementRecord'));
        this.holder.name = movementRecord.holderName ? this.formatter.formatToTitleCase(movementRecord.holderName) : null;
        this.holder.document = movementRecord.holderDocumentNumber ? this.formatter.formatCpf(movementRecord.holderDocumentNumber) : null;
        this.holder.admissionDate = movementRecord.eventDate ? this.formatter.formatDate(movementRecord.eventDate) : null;
        this.holder.registrationPlate = movementRecord.registrationPlate ? movementRecord.registrationPlate : null;
        this.holder.registrationComplement = movementRecord.registrationComplement ? movementRecord.registrationComplement : null;
        this.holder.cardNumber = movementRecord.beneficiaryCard ? movementRecord.beneficiaryCard : null;
      } else {
        this.holder = this.holderInformationData;
        this.holder.name = this.holder.name ? this.formatter.formatToTitleCase(this.holder.name) : null;
        this.holder.document = this.holder.document ? this.formatter.formatCpf(this.holder.document) : null;
        this.holder.admissionDate = this.holder.admissionDate ? this.formatter.formatDate(this.holder.admissionDate) : null;
        this.holder.registrationPlate = this.holder.registrationPlate ? this.holder.registrationPlate : null;
        this.holder.registrationComplement = this.holder.registrationComplement ? this.holder.registrationComplement : null;
        this.holder.cardNumber = this.holder.cardNumber ? this.holder.cardNumber : null;
      }
    },
    observerHolderInformationData() {
      this.$emit('observerHolderInformationData', this.holder);
    },
    saveAdmissionDate(admissionDate) {
      if (admissionDate && admissionDate.length === 10) {
        this.$emit('saveAdmissionDate', admissionDate);
      }
    },
  },

  created() {
    this.formatter = new Formatters();
    this.rule = new Rules();
  },
});
</script>
