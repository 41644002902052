/* eslint-disable */
import HttpService from '../HttpService'

export default class FileDataService {
  constructor() {
    this._httpService = new HttpService('/sdi/file-data');
    this._httpReportService = new HttpService('/sdi/report/movement-record/file');
  }
  
  async FindByParams(params) {
    return await this._httpService.get(`?${params}`);
  }

  async downloadFile(id){
    return await this._httpService.get(`/${id}/data`, null, { responseType: 'blob' });
  }

  async DownloadReport(id){
    return await this._httpReportService.get(`/${id}`, null, { responseType: 'blob' });
  }

  async downloadFileMovementCritical(id){
    return await this._httpService.get(`/movement-critical/${id}/data`, null, { responseType: 'blob' });
  }

  async movementCancellation(id){
    return await this._httpService.post(`/${id}/movement-cancellation`);
  }
}
